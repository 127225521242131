export const exitGame = () => {
  try {
    if (typeof window !== 'undefined') {
      window.ReactNativeWebView?.postMessage('exit')
      window.parent?.postMessage({ type: 'message', data: { eventKey: 'exit' } }, '*')
    }
  } catch (e) {
    console.error('[exit error]', e)
  }
}

export const readyGame = () => {
  try {
    if (typeof window !== 'undefined') {
      window.ReactNativeWebView?.postMessage('ready')
      window.parent?.postMessage({ type: 'message', data: { eventKey: 'ready' } }, '*')
    }
  } catch (e) {
    console.error('[ready error]', e)
  }
}
