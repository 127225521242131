import io from 'socket.io-client'

export function getSocketIo(url, auth_token) {
  return io(url, {
    autoConnect: true,
    reconnection: true, //手動設定
    reconnectionDelay: 1000,
    reconnectionDelayMax: 3000,
    timeout: 10000,
    extraHeaders: {
      auth_token
    }
  })
}

export default getSocketIo
