import { useMemo } from 'react'
import { exitGame } from '@/core/native'

export function BackButtonBar() {
  const backButton = useMemo(() => {
    if (typeof window !== 'undefined') {
      const params = new URLSearchParams(window.location.search)
      const backButtonStr = params.get('backButton') ?? 'false'
      return backButtonStr === 'true'
    }
    return true
  }, [])

  if (!backButton) return null
  return (
    <>
      <div className="  fixed top-0 left-0 right-0  z-[4000] w-full bg-[#25263A] border-[#434454] border-b-2">
        <div className="p-[16px]">
          <div onClick={() => exitGame()}>
            <svg width="30" height="30" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M62.5 75L37.5 50L62.5 25" stroke="white" strokeOpacity="0.6" strokeWidth="8.33333" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </div>
        </div>
      </div>
      <div className="w-full">
        <div className="h-[64px]"></div>
      </div>
    </>
  )
}
